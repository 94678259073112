import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
//import { useTranslation } from 'react-i18next';
import LeftNavigation from '../../components/LeftNavigation';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { rootUrl } from '../../config';

import StoreContext from '../../state/context/store';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Tab, Tabs, Snackbar } from '@material-ui/core';
import UserManagement from './user-management';
import PodManagement from './pod-management';
import { Alert, AlertTitle } from '@material-ui/lab';

import TokenManager from '../../service/tokens';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    overflow: 'auto',
    paddingTop: 40,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  selectWrapper: {
    width: '100%',
    textAlign: 'left',
  },
  tabPanel: {
    height: '100%',
    '& .MuiTab-root': {
      backgroundColor: 'rgba(0,0,0,.05)',
      margin: '0 15px',
    },
    '& .Mui-selected': {
      backgroundColor: 'white',
      boxShadow: '0px 0px 8px 0 rgb(44 0 64 / 15%)',

      '& .MuiTab-wrapper': {
        color: '#2c0040',
        fontSize: '1rem',
        fontFamily: 'Oswald',
        fontWweight: 700,
        letterSpacing: '0.5px',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6e3694',
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#6e3694',
    },
  },
  loading: {},
  tab: {
    fontSize: '1rem',
    fontFamily: 'Oswald',
  },
}));

const AdminPortal = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [store] = useContext(StoreContext);
  const { me } = store;
  const [tab, setTab] = useState(0);
  const [pods, setPods] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [snacking, setSnacking] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  //const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnacking(false);
  };

  useEffect(() => {
    // axios
    //   .all([
    //     axios.get(`${rootUrl}/api/get/pod/users`, { withCredentials: true }),
    //     axios.get(`${rootUrl}/api/all-pods`, { withCredentials: true }),
    //   ])
    //   .then(
    //     axios.spread((...responses) => {
    //       const users = responses[0]?.data;
    //       const pods = responses[1]?.data;

    //       // console.log('calling for all pods/users: ', pods, users);

    //       setUsers(users);
    //       updatePodCounts(pods);
    //       setLoaded(true);
    //     })
    //   );

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const tm = TokenManager.getInstance();
    tm.addToken({ id: 'users', source });

    axios
      .get(`${rootUrl}/api/get/pod/users`, {
        withCredentials: true,
        cancelToken: source.token,
      })
      .then(({ data }) => {
        //move all users with a pending status to the tippity top
        const sorted = data.filter((user) =>
          user.podProfile.podMembers.some((m) => m.status === 'pending')
        );
        //add user that aren't pending, or are coordinators, or haven't joined a pod
        sorted.push(
          data.filter(
            (user) =>
              !user.podProfile.podMembers.filter((m) => m.status === 'pending')
                .length ||
              (user.podProfile.podCoordinators.length > 0 &&
                (!user.podProfile.podMembers.length ||
                  !user.podProfile.podMembers.some(
                    (m) => m.status === 'pending'
                  ))) ||
              (user.podProfile.podMembers.length === 0 &&
                user.podProfile.podCoordinators.length === 0)
          )
        );
        setUsers(sorted.flat().filter((a) => a));
        setLoaded(true);
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      })
      .finally(() => {
        tm.removeToken('users');
        console.log(tm);
      });
  }, []);

  //get pod from query string
  return (
    <div className={styles.container}>
      <LeftNavigation></LeftNavigation>
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.tabPanel}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Admin tabs"
          >
            <Tab label={'USER MANAGEMENT'} className={classes.tab} />
            <Tab label={'POD MANAGEMENT'} className={classes.tab} />
          </Tabs>
          <Paper square className={classes.tabPanel}>
            {tab === 0 && (
              <UserManagement
                className={classes.tabPanel}
                users={users}
                setUsers={setUsers}
                loaded={loaded}
                me={me}
                history={history}
                setSnacking={setSnacking}
                setMessage={setMessage}
                setSeverity={setSeverity}
              />
            )}
            {tab === 1 && (
              <PodManagement
                className={classes.tabPanel}
                tab={tab}
                pods={pods}
                setPods={setPods}
                // loaded={loaded}
                // setLoaded={setLoaded}
                me={me}
                history={history}
                setSnacking={setSnacking}
                setMessage={setMessage}
                setSeverity={setSeverity}
              />
            )}
          </Paper>
        </div>
      </Container>
      <Snackbar
        open={snacking}
        autoHideDuration={6000}
        // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={severity}>
          <AlertTitle>
            {severity[0].toUpperCase() +
              severity.substr(1, severity.length - 1)}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminPortal;
