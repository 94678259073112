import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const Multiselect = (props) => {
  const { options, onChange, value, disabled, maxWidth, placeHolder } = props;
  const [opts, setOpts] = useState(options);

  useEffect(() => {
    setOpts(options);
  }, [options]);

  const customMultiselect = {
    container: (base) => ({
      ...base,
      borderRadius: '16px !important',
      width: `${maxWidth ? maxWidth : 300}px`,
      border: 'solid 1px #dfe1da',
      '&:active': {
        borderColor: 'rgba(166,58,170,1)',
      },
      '&:hover': {
        borderColor: 'rgba(113,59,144,1)',
      },
      '&:focus': {
        borderColor: 'rgba(54,179,168,1)',
      },
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: '16px !important',
      backgroundColor: 'white !important',
      width: '100%',
      maxWidth: `${maxWidth ? maxWidth : 300}px`,
      minHeight: '48px',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
      '&.select__control--is-disabled': {
        opacity: 0.3,
      },
      '& .select__indicator': {
        color: '#6e3694',
      },
      '& .select__placeholder': {
        fontWeight: 500,
        fontFamily: 'Archivo Narrow',
        color: '#6e3694',
      },
      '& .select__indicator-separator': {
        color: '#E0D5EA',
        backgroundColor: '#E0D5EA',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      border: 'none',
      outline: 'none',
      fontSize: '1em',
      fontWeight: 600,
      color: 'rgba(29, 20, 53, 1)',
      padding: '0.6em 1.4em 0.5em 0.8em',
      maxWidth: `${maxWidth ? maxWidth : 300}px`,
      margin: 0,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        textAlign: 'left',

        backgroundColor: isDisabled
          ? null
          : isSelected
          ? 'white'
          : isFocused
          ? 'rgb(242, 230, 255)'
          : null,

        ':active': {
          backgroundColor:
            !isDisabled && (isSelected ? 'white' : 'rgb(242, 230, 255)'),
        },
      };
    },
  };

  return (
    <Select
      onChange={onChange}
      options={opts}
      defaultValue={''}
      isMulti
      name="contact"
      placeholder={placeHolder}
      isDisabled={disabled}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={customMultiselect}
      value={value}
      closeMenuOnSelect={false}
      aria-label="Multiple select"
    />
  );
};

export default Multiselect;
