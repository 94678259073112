import React from 'react';
import styles from './index.module.scss';
import Map from './map';
import LeftNavigation from '../../components/LeftNavigation';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  memberGrid: {
    maxHeight: 500,
  },
  map: {
    height: 'auto !important',
    // width: "auto !important",
  },
}));

const PodsMap = (props) => {
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <LeftNavigation></LeftNavigation>
      <Container maxWidth="xl" className={styles.mapWrapper}>
        <div className={styles.body}>
          <div className={`${classes.root} ${styles.mapContainer}`}>
            <Map match={props.match} className={classes.map} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PodsMap;
