import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './index.module.scss';
import StoreContext from '../../../state/context/store';
import { loginPods } from '../../../state/actions/me';
import { rootUrl } from '../../../config';
import Multiselect from '../../../components/Multiselect';
// import Input from '../../../components/Input';
import { TextField } from '@material-ui/core';

const About = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [context, dispatch] = useContext(StoreContext);
  const [reason, setReason] = useState(null);
  // const [reasonError, setReasonError] = useState('');
  const [about, setAbout] = useState('');
  const [aboutError, setAboutError] = useState('');
  const [saving, setSaving] = useState(false);
  const [multiSelectOptions] = useState([
    { label: 'Advocacy opportunities', value: 'advocacy_opportunities' },
    { label: 'Outreach', value: 'outreach' },
    { label: 'Mentorship', value: 'mentorship' },
    { label: 'Networking', value: 'networking' },
    {
      label: 'Friendship/being part of a community',
      value: 'friendship_community',
    },
    { label: 'JEDI literacy', value: 'jedi' },
    { label: 'Looking for a safe space', value: 'safe_space' },
    {
      label: 'Professional skills development',
      value: 'professional_development',
    },
  ]);

  const save = () => {
    let errors = 0;

    // if (!reason) {
    //   setReasonError('Please select at least one reason.');
    //   errors++;
    // }
    if (!about) {
      setAboutError('Please select at least one reason.');
      errors++;
    }
    if (errors === 0) {
      updateMember();
    }
  };

  const updateMember = async () => {
    setSaving(true);
    console.log('context me: ', context.me);
    await axios
      .patch(
        `${rootUrl}/api/podProfile/${context.me.userId || context.me.id}`, //adding || context.me.id as fallback, sometimes userId is missing. Dunno why?
        {
          reasons: reason?.map((r) => r.value) || null,
          about: about ? about : null,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          loginPods(dispatch, response.data);
          //for whatever reason, the profile page uses the user id to fetch the profile, not the profileid
          history.push(`/profile/${context.me.userId || context.me.id}`);
        }
      })
      .catch((error) => {
        setSaving(false);
        console.log('ERR', error);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}></div>
      <h1 className={styles.title}>
        {t('Welcome, ')} {context.me.name}
      </h1>
      <form className={styles.form}>
        <label htmlFor="reason" className={styles.subtitle}>
          {t('Please tell us why you would like to join a pod')}
        </label>
        <div className={styles.multiselectContainer}>
          <Multiselect
            options={multiSelectOptions.map((option) => ({
              ...option,
              label: t(option.label),
            }))}
            onChange={(e) => {
              // setReasonError('');
              setReason(e);
            }}
            value={reason}
          />
        </div>
        <label htmlFor="about" className={styles.subtitle}>
          {t('Please tell us a little bit about yourself')}
        </label>
        {/* <Input
          type="text"
          id="about"
         
          maxLength={1000}
          onChange={(e) => {
            setAboutError('');
            setAbout(e.target.value);
          }}
          error={aboutError}
        /> */}

        <TextField
          id="about"
          multiline
          className={styles.tallInput}
          value={about}
          onInput={(e) => {
            if (e.target.value.length > 255) {
              e.target.value = e.target.value.slice(0, 255);
            }
          }}
          onChange={(e) => {
            setAboutError('');
            setAbout(e.target.value);
          }}
          helperText={aboutError}
          error={aboutError.length > 1}
          variant="outlined"
          rows={11}
          rowsMax={11}
          maxLength={1000}
        />
      </form>
      <div className={styles.signup}>
        <button onClick={save} disabled={saving}>
          {t('Submit Request to Join')}{' '}
          {saving && (
            <i
              className="fas fa-circle-notch fa-spin"
              style={{ width: '16px', height: '16px', fontSize: '16px' }}
            ></i>
          )}
        </button>
      </div>
    </div>
  );
};

export default About;
