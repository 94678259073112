import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { rootUrl } from '../../config';
import styles from './index.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    flexGrow: 1,
    '& .MuiPaper-root': {
      maxHeight: 550,
    },
  },
  tableBody: {
    minWidth: 550,
    overflow: 'auto',
  },
  goButton: {
    color: '#2c0040',
  },
  select: {
    minWidth: 135,
  },
}));

const types = [
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'removed', label: 'Removed' },
  { value: 'coordinator', label: 'Coordinator' },
];

const UserDetails = (props) => {
  const {
    // history,
    data,
    open,
    setOpen,
    users,
    setUsers,
    setSnacking,
    setMessage,
    setSeverity,
  } = props;
  const classes = useStyles();
  const [status, setStatus] = useState([]);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    console.log(data);
    if (data) {
      // setup immutable status object
      const statuses = [];
      data.podProfile.podMembers.forEach((member) => {
        statuses[member.podId] = member.status;
      });

      data.podProfile.podCoordinators.forEach((coordinator) => {
        statuses[coordinator.podId] = 'coordinator';
      });

      setStatus(statuses);
    }
  }, [data]);

  const handleClose = () => {
    setDirty(false);
    setOpen(false);
  };

  const handleUpdate = () => {
    //see if any coordinator positions need to be demoted
    const saves = [];
    // look for any coordinators that have been removed
    data.podProfile.podCoordinators
      .filter((coordinator) => status[coordinator.podId] !== 'coordinator')
      .forEach((coordinator) => {
        coordinator.status = status[coordinator.podId];
        saves.push(
          axios.post(
            `${rootUrl}/api/coordinators/demote`,
            {
              podId: coordinator.podId,
              coordinators: [coordinator],
            },
            { withCredentials: true }
          )
        );
      });

    const membership = [...data.podProfile.podMembers];

    membership.forEach((member) => {
      member.status = status[member.podId];
    });

    saves.push(
      axios.post(
        `${rootUrl}/api/update/membership`,
        {
          membership,
        },
        { withCredentials: true }
      )
    );

    axios
      .all(saves)
      .then(
        axios.spread((...responses) => {
          const results = responses[0];
          if (results.status === 200) {
            //I think a race issue, moving out of the all call
            axios
              .post(
                `${rootUrl}/api/get/pod/user`,
                { id: data.id },
                { withCredentials: true }
              )
              .then((result) => {
                if (result.status === 200) {
                  setMessage('Membership updated!');
                  setSeverity('success');
                  setSnacking(true);
                  setDirty(false);
                  setOpen(false);
                  const updateUsers = [...users];
                  const updatedUser = updateUsers.find((u) => u.id === data.id);
                  updatedUser.podProfile.podMembers =
                    result.data.podProfile.podMembers;
                  updatedUser.podProfile.podCoordinators =
                    result.data.podProfile.podCoordinators;
                  updatedUser.isCoordinator =
                    result.data.podProfile.podCoordinators.length > 0;
                  setUsers(updateUsers);
                }
              })
              .catch((ex) => {
                setSnacking(true);
                setMessage('Memebership failed to update.');
                setSeverity('error');
              });
          }
        })
      )
      .catch((ex) => {
        setSnacking(true);
        setMessage('Memebership failed to update.');
        setSeverity('error');
      });
  };

  const getPodName = (id, pod) => {
    pod = pod || data.podProfile.pods.find((p) => p.id === id);
    return pod?.name;
  };

  // const goToPod = (id) => {
  //   history.push('/pods/' + id);
  // };

  const handleStatusChange = (e, member) => {
    const statuses = [...status];
    statuses[member.podId] = e.target.value;
    setStatus(statuses);
    setDirty(true);
  };

  const profiles =
    data.podProfile?.podMembers
      .concat(data.podProfile?.podCoordinators)
      .flat() || [];

  //get pod from query string
  return (
    <div>
      <Dialog
        open={open}
        className={classes.dialogRoot}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {data?.name} Pod Details
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Pod</TableCell>
                  <TableCell>Membership</TableCell>
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {profiles.map((profile) => (
                  <TableRow key={profile.podId}>
                    <TableCell component="th" scope="row">
                      {getPodName(profile.podId, profile.pod)}
                    </TableCell>
                    <TableCell>
                      <Select
                        className={classes.select}
                        value={status[profile.podId]}
                        variant="outlined"
                        onChange={(e) => handleStatusChange(e, profile)}
                      >
                        {types.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    {/* <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => goToPod(member.podId)}
                        component="span"
                      >
                        <i
                          className={`fas fa-arrow-circle-right ${classes.goButton}`}
                        ></i>
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.purpleButton}
            onClick={handleUpdate}
            disabled={!dirty}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
