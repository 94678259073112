import React, { useContext, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LeftNavigation from '../../components/LeftNavigation';
import QuickProfile from '../../components/Pods/QuickProfile';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { rootUrl } from '../../config';
import StoreContext from '../../state/context/store';

const Profile = (props) => {
  const { match } = props;
  // const { t } = useTranslation();
  const [store] = useContext(StoreContext);
  const { me } = store;
  const [edit, setEdit] = useState(false);
  const [account, setAccount] = useState({});
  const accountId = parseInt(match.params.id, 10);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios(`${rootUrl}/api/podProfile/${accountId}`, {
        withCredentials: true,
      });
      setAccount(response.data);
      // if (accountId === me.id && accountId !== undefined) {
      //   setMe(dispatch, response.data);
      // }
    }
    fetchData();
  }, [accountId]);

  useEffect(() => {
    //removing admin's ability to edit the contents of a user's profile
    // || me.isAdmin
    if (account.id === me.id && account.id !== undefined) {
      setEdit(true);
    }
  }, [account.id, me.id, me.isAdmin]);

  return (
    <div className={styles.container}>
      <LeftNavigation></LeftNavigation>
      <div className={styles.body}>
        <div className={styles.left}>
          <div>
            <QuickProfile edit={edit} name={account.name} account={account} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
