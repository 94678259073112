// @flow
import React from 'react';
import styles from './index.module.scss';

const Input = (props) => {
  const {
    value,
    type = 'text',
    id,
    onChange,
    error,
    placeholder,
    className,
    isCentered = true,
    ...rest
  } = props;

  return (
    <div className={styles.container}>
      <input
        {...rest}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        className={className}
        onChange={onChange}
        aria-label="Enter text here"
      ></input>
      <div
        className={`${styles.errMessage} ${
          isCentered ? '' : styles.errMessageLeft
        }`}
      >
        {error}
      </div>
    </div>
  );
};

export default Input;
